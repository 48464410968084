<template>
    <div class="div-conteudo">
        <transition name="modal" v-if="carregando">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">
                        <img src="../../assets/imagens/loadingSpinner.gif" alt="Roda de carregamento girando">
                        <label>Aguarde. Carregando.</label>
                    </div>
                </div>
            </div>
        </transition>
        <div class="fora" v-else>
            <div class="index centro">
                <div class="flexbox">
                    <div class="sistemas-admin">
                        <h2>Sistemas Internos</h2>
                        <div class="gridbox">
                            <div class="blocos">
                                <template v-for="sistemaAdmin in sistemasAdmin">
                                    <template>
                                        <div :key="sistemaAdmin.id" class="item">
                                            <a @click.prevent="acessarUrl(sistemaAdmin.sigla, sistemaAdmin.url)" target="_blank" rel="noopener noreferrer">
                                                <img :src="sistemaAdmin.logo" :alt="`Logo de ${sistemaAdmin.sigla}`">
                                            </a>
                                        </div>
                                    </template>
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="sistemas-publicos">
                        <h2>Sistemas Públicos</h2>
                        <div class="gridbox">
                            <div class="blocos">
                                <template v-for="sistemaPublico in sistemasPublicos">
                                    <template>
                                        <div :key="sistemaPublico.id" class="item">
                                            <a @click.prevent="acessarUrlPublico(sistemaPublico.url)" target="_blank" rel="noopener noreferrer">
                                                <img :src="sistemaPublico.logo" :alt="`Logo de ${sistemaPublico.sigla}`">
                                            </a>
                                        </div>
                                    </template>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import metaDados from "../../helpers/metaDados";
    import HomeApi from "../../services/HomeApi";
    import Auth from "../../services/Auth";

    export default {
        name: "Home",
        metaInfo() {
            return {
                title: "Portal",
                titleTemplate: "%s - Casa Civil do Estado de Goiás",
                meta: [
                    {
                        vmid: "og:title",
                        property: "og:title",
                        content: "Portal - Casa Civil do Estado de Goiás",
                    },
                    ...metaDados(this.$route),
                ],
            };
        },
        data() {
            return {
                ambienteAtual: process.env.VUE_APP_ENV,
                sistemasAdmin: [],
                sistemasPublicos: [],
                carregando: true,
                erro: false,
            };
        },
        created() {
            this.homeApi = new HomeApi();
            this.auth = new Auth();
            this.carregarSistemas();
        },
        methods: {
            acessarUrl: async function (sistemaSigla, urlSistema) {
                try {
                    this.erro = null;

                    //Recupera se o usuário está logado no Portal e retorna o login dele
                    //se não estiver logado, retorna para a página de login do Portal
                    let usuarioLogado = await this.homeApi.validarAcesso(sistemaSigla)

                    if(usuarioLogado) {
                        try {
                            await this.homeApi.abrirNovaUrl(usuarioLogado, sistemaSigla, urlSistema)
                        } catch (err) {
                            this.erro =
                                typeof err === "string"
                                ? err
                                : "Houve um erro ao tentar abrir a URL. Por favor tente novamente";
                        }
                    }
                } catch (err) {
                    this.erro =
                        typeof err === "string"
                        ? err
                        : "Houve um erro ao tentar acessar o sistema. Por favor tente novamente";
                }
            },
            acessarUrlPublico: async function (urlSistema) {
                try {
                    this.erro = null;

                    window.open(urlSistema)                    
                } catch (err) {
                    this.erro =
                        typeof err === "string"
                        ? err
                        : "Houve um erro ao tentar acessar o sistema. Por favor tente novamente";
                }
            },
            carregarSistemas: async function () {
                try {
                    this.carregando = true;
                    this.erro = false;

                    if (this.homeApi.cancelTokens.getSistemasData) {
                        this.homeApi.cancelTokens.getSistemasData.cancel();
                    }

                    let usuario = this.auth.usuario;
                    let sistemasAdminData = await this.homeApi.getSistemasAdminData(usuario.id);
                    let sistemasPublicosData = await this.homeApi.getSistemasPublicosData(usuario.id);

                    let ambiente;
                    if (this.ambienteAtual === "development") {
                        ambiente = "desenvolvimento";
                    } else if (this.ambienteAtual === "homologacao") {
                        ambiente = "homologacao";
                    } else {
                        ambiente = "producao";
                    }

                    // Processa todos os sistemas admin e associa os logos diretamente
                    await Promise.all(sistemasAdminData.map(async (sistemaAdmin) => {
                        try {
                            let urlsData = await this.homeApi.getUrlsData(sistemaAdmin.identificador_sistema, ambiente);
                            if (urlsData !== null) {
                                sistemaAdmin.url = urlsData;
                                this.carregarLogoSistema(sistemaAdmin); // Carrega o logo e associa ao sistema
                                this.sistemasAdmin.push(sistemaAdmin);
                            }
                        } catch (error) {
                            return;
                        }
                    }));

                    // Processa todos os sistemas públicos e associa os logos diretamente
                    await Promise.all(sistemasPublicosData.map(async (sistemaPublico) => {
                        try {
                            let urlsData = await this.homeApi.getUrlsData(sistemaPublico.identificador_sistema, ambiente);
                            if (urlsData !== null) {
                                sistemaPublico.url = urlsData;
                                this.carregarLogoSistema(sistemaPublico); 
                                this.sistemasPublicos.push(sistemaPublico);
                            }
                        } catch (error) {
                            return;
                        }
                    }));

                    // Ordena os sistemas em ordem alfabética pelo identificador_sistema após o carregamento
                    this.sistemasAdmin.sort((a, b) => a.identificador_sistema.localeCompare(b.identificador_sistema));
                    this.sistemasPublicos.sort((a, b) => a.identificador_sistema.localeCompare(b.identificador_sistema));

                    this.carregando = false;
                } catch (err) {
                    if (this.homeApi.isCancel(err)) {
                        return;
                    }
                    this.carregando = false;
                    this.erro = true;
                }
            },
            carregarLogoSistema: function (sistema) {
                try {
                    const imagemPath = require(`../../assets/imagens/logo-${sistema.identificador_sistema}.png`);
                    sistema.logo = imagemPath; // Associa diretamente ao sistema
                } catch (error) {
                    const imagemPathPadrao = require(`../../assets/imagens/logo-casa-bco.svg`);
                    sistema.logo = imagemPathPadrao; // Associa o logo padrão em caso de erro
                }
            }
        },
    };
</script>

<style lang="scss" scoped>
    .gridbox {
        margin: 2rem 0 0 0;
    }

    @media screen and (min-width: "1066px") {
        .div-conteudo {
            width: 100vw;
            margin-bottom: 5rem;
            .centro {
                max-width: 96vw;
                margin: 0 auto;
            }
            .index {
                .flexbox {
                    display: flex;
                    gap: 3rem;

                    h2 {
                        font-family: "Montserrat-Bold";
                        color: #005516;
                        font-size: 1.3rem;
                        text-align: center;
                        margin-top: 1rem;
                    }

                    .gridbox {
                        display: grid;
                        gap: 3rem;

                        .blocos {
                            display: flex;
                            gap: .5rem;
                            flex-wrap: wrap;
                            align-content: stretch;
                            justify-content: space-evenly;
                            align-items: center;
                            flex-direction: row;

                            .item {
                                display: block;
                                width: 13rem;
                                height: 12rem;
                                min-height: max-content;
                                background-color: rgba(255, 255, 255, 0.171);
                                border-radius: 0.5rem;
                                box-shadow: 0 3px 10px rgb(0 0 0 / 0.45);
                                padding: 1.5rem .5rem;
                                align-content: center;

                                a {
                                    display: block;
                                    cursor: pointer;
                                    height: 100%;
                                    width: 100%;
                                    margin-bottom: 0rem;
                                    object-fit: contain;

                                    img {
                                        cursor: pointer;
                                        height: 100%;
                                        width: 100%;
                                        object-fit: contain;
                                    }
                                }
                                a:hover {
                                    padding: .2rem;
                                }
                            }

                            .item:hover {
                                background-color: #f0f7f1;
                            }
                        }
                    }

                    .sistemas-admin {
                        width: 100%;
                    }

                    .sistemas-publicos {
                        width: 100%;
                    }
                }
            }
        }
    }
    @media screen and (min-width: "950px") and (max-width: "1065px") {
        .div-conteudo {
            width: 100vw;
            margin-bottom: 5rem;
            .centro {
                max-width: 96vw;
                margin: 0 auto;
            }
            .index {
                h2 {
                    font-family: "Montserrat-Bold";
                    color: #005516;
                    font-size: 1.3rem;
                    text-align: center;
                }
                .topo-index {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 1.5rem;
                    margin-top: 1.5rem;

                    h1 {
                        font-family: "Montserrat-Bold";
                        color: #ffa600;
                        font-size: 1.8rem;
                    }
                }
                .gridbox {
                    display: grid;
                    gap: 2rem;
                    padding: 0 2rem;
                    .sistemasAdmin {
                        h3 {
                            color: #005516;
                            margin: 0 0 1.5rem 0;
                        }
                    }
                    .sistemasPublicos {
                        h3 {
                            color: #005516;
                            margin: 0 0 1.5rem 0;
                        }
                    }
                    .blocos {
                        display: flex;
                        gap: 1rem;
                        flex-wrap: wrap;
                        align-content: stretch;
                        justify-content: space-evenly;
                        align-items: center;
                        flex-direction: row;
                        .item {
                            display: block;
                            width: 20rem;
                            height: 15rem;
                            min-height: max-content;
                            background-color: rgba(255, 255, 255, 0.171);
                            border-radius: 0.5rem;
                            box-shadow: 0 3px 10px rgb(0 0 0 / 0.45);
                            padding: 2rem .5rem;
                            align-content: center;

                            h1 {
                                font-weight: bold;
                                font-size: 1.3rem;
                                color: #be7c01;
                                cursor: default;
                            }

                            a {
                                display: block;
                                cursor: pointer;
                                max-height: 11rem;
                                height: 100%;
                                width: 100%;
                                margin-bottom: 0rem;
                                object-fit: contain;
                                
                                img {
                                    cursor: pointer;
                                    height: 100%;
                                    width: 100%;
                                    object-fit: contain;
                                }
                            }
                            a:hover {
                                padding: .2rem;
                            }
                        }

                        .item:hover {
                            background-color: #f0f7f1;
                        }
                    }
                }
            }
        }
    }
    @media screen and (min-width: "60px") and (max-width: "949px") {
        .div-conteudo {
            width: 100vw;
            margin-bottom: 5rem;
            .centro {
                max-width: 96vw;
                margin: 0 auto;
            }
            .index {
                h2 {
                    font-family: "Montserrat-Bold";
                    color: #005516;
                    font-size: 1.3rem;
                    text-align: center;
                }
                .topo-index {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 1.5rem;
                    margin-top: 1.5rem;

                    h1 {
                        font-family: "Montserrat-Bold";
                        color: #ffa600;
                        font-size: 1.8rem;
                    }
                }
                .gridbox {
                    padding: 0;
                    .sistemasAdmin {
                        h3 {
                            color: #005516;
                            margin: 0 0 1.5rem 0;
                        }
                    }
                    .sistemasPublicos {
                        h3 {
                            color: #005516;
                            margin: 0 0 1.5rem 0;
                        }
                    }
                    .blocos {
                        display: flex;
                        gap: 1rem;
                        flex-wrap: wrap;
                        align-content: stretch;
                        justify-content: space-evenly;
                        align-items: center;
                        flex-direction: row;
                        .item {
                            display: block;
                            width: 20rem;
                            height: 15rem;
                            min-height: max-content;
                            background-color: rgba(255, 255, 255, 0.171);
                            border-radius: 0.5rem;
                            box-shadow: 0 3px 10px rgb(0 0 0 / 0.45);
                            padding: 2rem .5rem;
                            align-content: center;

                            h1 {
                                font-weight: bold;
                                font-size: 1.3rem;
                                color: #be7c01;
                                cursor: default;
                            }

                            a {
                                display: block;
                                cursor: pointer;
                                max-height: 11rem;
                                height: 100%;
                                width: 100%;
                                margin-bottom: 0rem;
                                object-fit: contain;
                                
                                img {
                                    cursor: pointer;
                                    height: 100%;
                                    width: 100%;
                                    object-fit: contain;
                                }
                            }
                            a:hover {
                                padding: .2rem;
                            }
                        }

                        .item:hover {
                            background-color: #f0f7f1;
                        }
                    }
                }
            }
        }
    }
    .modal-mask {
        position: fixed;
        z-index: 1000;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        display: table;
        transition: opacity 0.3s ease;
    }
    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }
    .modal-container {
        width: fit-content;
        height: 0px auto;
        margin: 0px auto;
        position: relative;
        overflow-y: auto;
        overflow-x: auto;
        font-size: 1.5rem;
        font-family: "Montserrat-Medium";

        img {
            width: 2rem;
            height: 2rem;
            margin-right: 1.6rem;
        }

        label {
            font-size: 1.3rem;
            color: rgba(255, 255, 255, 0.556);
            display: inline;
            position: relative;
            top: 0rem;
        }
    }
</style>