<template>
  <div>
    <div class="tela-login">
      <div class="right">
        <h1>Redefinir Senha</h1>
        <form @submit.prevent="handleSubmit">
          <div class="item">
            <label>Nova senha:</label>
            <input type="password" name="senha" id="senha" class="form-control" placeholder="Senha"
              v-model="senha" />
            <br>

            <label>Confirmação da nova senha:</label>
            <input type="password" name="confirmacao_senha" id="confirmacao_senha" class="form-control"
              placeholder="Confirmação da senha" v-model="confirmacao_senha" />
          </div>

          <button type="submit" class="btn-enviar">Enviar</button>

          <div class="notificacao" id="notificacao">
            <p class="mensagem" id="mensagem"></p>
          </div>
        </form>
      </div>
      <div class="footer-mobile">
        <img title="Logo da Casa Civil e Estado de Goiás" src="../../assets/imagens/logo-casa-bco.png" class="logo"
          alt="Logo da Casa Civil e Estado de Goiás" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import metaDados from "../../helpers/metaDados";

export default {
  name: 'RedefinirSenha',
  metaInfo() {
    return {
      title: "Portal",
      titleTemplate: "%s - Casa Civil do Estado de Goiás",
      meta: [
        {
          vmid: "description",
          name: "description",
          property: "description",
          content:
            "Página de acesso e cadastro de cessões/disposições de servidores.",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content:
            "Página de acesso e cadastro de cessões/disposições de servidores.",
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: "Portal - Casa Civil do Estado de Goiás",
        },
        ...metaDados(this.$route),
      ],
    };
  },
  data() {
    return {
      login: '',
      senha: '',
      confirmacao_senha: '',
      token: '',
    }
  },
  methods: {
    async handleSubmit() {
      const data = {
        login: this.$router.currentRoute.query.login,
        senha: this.senha,
        confirmacao_senha: this.confirmacao_senha,
        token: this.$router.currentRoute.query.token,
      };

      if (this.senha == this.confirmacao_senha) {
        this.mensagemSucesso();
        axios.post(`${process.env.VUE_APP_BASE_API_URL}/api/v1/sessoes/redefinir_senha`, data)
          .then(
            res => {
              this.$router.push({
                path: "/sessoes/autenticar",
                params: {
                  response: res,
                }
              });
            }
          ).catch(
            err => {
              console.log(err)
              this.mensagemFalha2();
            }
          )
      } else {
        data.senha = ''
        data.confirmacao_senha = ''
        this.mensagemFalha();
      }
    },
    mensagemSucesso() {
      document.getElementById("notificacao").style.visibility = "visible"
      document.getElementById("mensagem").style.color = "green"
      document.getElementById("mensagem").innerHTML = "Sua senha foi redefinida com sucesso!"
      document.getElementById("mensagem").style.display = "block"
    },
    mensagemFalha() {
      document.getElementById("notificacao").style.visibility = "visible"
      document.getElementById("mensagem").style.color = "red"
      document.getElementById("mensagem").innerHTML = "As senhas precisam coincidir!"
      document.getElementById("mensagem").style.display = "block"
    },
    mensagemFalha2() {
      document.getElementById("notificacao").style.visibility = "visible"
      document.getElementById("mensagem").style.color = "red"
      document.getElementById("mensagem").innerHTML = "Ocorreu um erro na mudança das senhas. Tente novamente!"
      document.getElementById("mensagem").style.display = "block"
    }
  }
}
</script>

<style lang="scss" scoped>
.tela-login {
  display: flex;
  flex-direction: column;
  //background-image: url("../../assets/imagens/bk-login.png");
  //background-attachment: fixed;
  //background-size: cover;
  //background-repeat: no-repeat;
  //background-position: left;
  background-color: #125b19;
  min-height: 100vh;
  justify-content: center;
  align-items: center;

  .mensagem {
    display: none;
    position: relative;
    text-align-last: center;
    font-size: 1.3rem;
  }

  .left {
    display: block;
    height: 10rem;

    .logo {
      width: 15rem;
      margin: 1rem 0 0;
    }

    .logo-casa {
      display: none;
    }
  }

  .right {
    background-color: white;
    //max-width: 22rem;
    padding: 2rem;
    min-width: 25rem;
    width: 50%;
    height: 30rem;
    border-radius: 4rem;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.61);

    h1 {
      margin: 1rem 0 2rem 0;
      font-family: 'Montserrat-Bolder';
      color: #00a339;
      text-align: center;
    }

    .item {
      display: grid;
      margin: 1rem 0;

      label {
        font-family: "Montserrat-Medium";
        color: #005516;
        margin-bottom: .5rem;
      }

      input:focus {
        outline: none;
        box-shadow: rgba(0, 0, 0, 0.404) 0 0 .3rem;
      }

      input {
        height: 3rem;
        padding-left: .5rem;
        border: silver .1rem solid;
        border-radius: 3rem;
        font-family: 'Montserrat';
        font-size: 1.2rem;
      }
    }

    a {
      margin: .5rem 0 0 1rem;
      color: #005516;
      text-align: left;
      font-size: 1.2rem;
      width: fit-content;
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline;
    }

    .btn-enviar {
      display: block;
      margin: 2rem auto 0 auto;
      background-color: #005516;
      color: white;
      font-family: 'Montserrat-Medium';
      font-size: 1.2rem;
      border: none;
      padding: .5rem 1.5rem;
      border-radius: 1rem;
      cursor: pointer;
      width: 14rem;
    }

    .btn-enviar:hover {
      background-color: #005517d5;
    }

    .btn-voltar {
      display: flex;
      background-color: #fff;
      color: #005516;
      font-family: 'Montserrat-Medium';
      font-size: 1rem;
      border: none;
      border-radius: 1rem;
      cursor: pointer;
      float: right;
      margin: 0rem 1rem 0rem 0;
      padding: 0;
    }

    .btn-voltar:hover {
      text-decoration: underline;
    }
  }

  .footer-mobile {
    img {
      width: 15rem;
      margin-top: 1rem;
    }
  }
}

@media screen and(min-width: '512em') {
  .tela-login {
    flex-direction: row;
    justify-content: space-between;

    .left {
      display: grid;
      width: 100%;
      justify-content: center;
      justify-items: center;
      height: 100%;

      .logo {
        width: 30rem;
      }

      .logo-casa {
        display: inline-block;
        width: 15rem;
        margin-top: 2rem;
        margin-left: -4%;
      }
    }

    .right {
      height: 100%;
      min-width: 25rem;
      min-height: 100vh;
      display: grid;
      padding: 0rem 3rem;
      align-content: center;
    }

    .footer-mobile {
      display: none;
    }
  }
}

.mensagem {
  display: none;
  position: relative;
  margin: 1.5rem 0rem -3rem 1rem;

  p {
    color: #bd0000;
  }
}
</style>